import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EditRequest from "./Modal/EditRequest";
import { useToggle } from "./../utils/hooks";
import {
  FetchUserDetail,
  GetStudentList,
  SchoolList,
  userDetailSelector,
} from "../features/slice.js";
import { postFormData } from "../api/index.js";
import { toast } from "react-toastify";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const getNotification = useCallback(() => {}, []);
  const list = useSelector((state) => state.notification.list);
  const detail = useSelector(userDetailSelector);
  const [loading, setLoading] = useState(false);
  const [show, handleShow] = useToggle(false);

  useEffect(() => {
    getNotification();
    dispatch(SchoolList({}));
    dispatch(GetStudentList({}));
    if (!detail) {
      dispatch(FetchUserDetail());
    }
  }, [dispatch, getNotification, detail]);

  const handleEditRequest = (val) => {
    console.log(val);
    postFormData("user/requestToEditProfile", {
      request_for_edit_consent: val,
      request_for_edit_profile: true,
    }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        dispatch(FetchUserDetail());
      } else {
        toast.error(res?.message);
      }
    });
  };
  const handleSubscribeNewsletter = (subs) => {
    postFormData("user/subscribeNewsletter", {
      user_id: detail?.id,
      status: !subs,
    }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        dispatch(FetchUserDetail());
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <div className="header-top-tab">
      <div className="mb-3">
        <img
          src="assets/img/headerlogo.png"
          className=" cursor-pointer"
          alt="Book in Class"
          title="Book in Class"
          onClick={() => {
            navigate("/dashboard", { replace: true });
          }}
        />
      </div>

      <nav className="navbar navbar-expand-lg navbar-light loginMenu ">
        <div className="collapse navbar-collapse " id="navbarNav">
          <ul className="navbar-nav homeNav">
            <li className="nav-item">
              <Link
                className={
                  location.pathname.includes("dashboard")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/dashboard"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname.includes("event")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/events"
              >
                All Events
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname.includes("book-class")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/book-class"
              >
                Make Booking
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname.includes("student")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/student"
              >
                Students
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname.includes("bookings")
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/bookings"
              >
                My Bookings
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="noti-flex">
        {/* <div className="notificatin-drop cursor-pointer">
          <img src="assets/img/notification.png" className="img-fluid" alt="" />
          <div className="notificatin-box">
            <h6>Notifications</h6>
            {list?.length > 0 ? (
              list?.map((item, i) => {
                return (
                  <div className="noti-text" key={i}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    <p className="day-time">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                      12:00pm
                    </p>
                  </div>
                );
              })
            ) : (
              <div>No new notification.</div>
            )}

            <div className="text-center mt-4">
              <Link to="/notification" className="fw-bold">
                See All Notification
              </Link>
            </div>
          </div>
        </div> */}
        <button
          className="btn btn-blue float-end menu-toggle-img d-lg-none collapsed"
          data-bs-target="#navbarNav"
          data-bs-toggle="collapse"
          aria-expanded="false"
        ></button>
        <div className="cart-img cursor-pointer">
          <Link to="/cart">
            <img src="assets/img/cart.png" className="img-fluid" alt="" />
          </Link>
        </div>
        <div className="profile-drop cursor-pointer position-relative">
          <div
            className="tabs-list shadow"
            onClick={() => {
              if (detail?.canEditProfile) {
                navigate("/edit-profile");
              }
            }}
          >
            <span>
              <img
                src={
                  detail?.image?.includes("http")
                    ? detail?.image
                    : "assets/img/headerlogo.png"
                }
                className="profile-img"
                alt=""
              />
            </span>
            <span>
              <h5 className="mb-0">
                {detail ? `${detail?.first_name} ${detail?.last_name}` : ""}
              </h5>
            </span>
          </div>
          <div className="profile-collapse notificatin-box">
            <div className="noti-text">
              <span
                onClick={() => {
                  navigate("/edit-profile");

                  // if (detail?.canEditProfile) {
                  //   navigate("/edit-profile");
                  // } else if (detail?.canEditProfile === false) {
                  //   toast.error(
                  //     "You have submitted an edit request already. You can edit your profile once request is accepted."
                  //   );
                  // } else {
                  //   handleShow();
                  // }
                }}
              >
                <img
                  src="assets/img/First-Name.png"
                  className=""
                  height={"15px"}
                  alt=""
                />{" "}
                {/* {detail?.canEditProfile
                  ? `Edit Profile`
                  : detail?.canEditProfile === null
                  ? "Request Edit"
                  : detail?.canEditProfile === false
                  ? "Request Pending"
                  : ""} */}
                Edit Profile
              </span>
            </div>
            <div className="noti-text">
              <Link to="/payments">
                <img src="assets/img/payment-summary.png" className="" alt="" />{" "}
                Payment Summary
              </Link>
            </div>
            <div className="noti-text">
              <Link to="/my-membership">
                <img src="assets/img/my-membership.png" className="" alt="" />{" "}
                My Membership
              </Link>
            </div>

            <div className="noti-text">
              <Link to="/change-password">
                <img src="assets/img/password.png" className="" alt="" /> Change
                Password
              </Link>
            </div>
            <div className="noti-text">
              <Link to="/account-cancellation">
                <img src="assets/img/my-membership.png" className="" alt="" />{" "}
                Account Cancellation
              </Link>
            </div>
            <div className="noti-text setting-nav">
              <span className="">
                <img src="assets/img/newsletters.png" className="" alt="" />{" "}
                Newsletter
              </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={detail?.isSubscribed}
                  onChange={() => {
                    handleSubscribeNewsletter(detail?.isSubscribed);
                  }}
                />
                <span>
                  <strong></strong>
                </span>
              </label>
            </div>
            <div className="text-center mt-4">
              <span
                className="fw-bold"
                style={{
                  color: "#ff2f2f",
                }}
                onClick={() => {
                  localStorage.removeItem('token_u');
                  navigate("/", { replace: true });
                }}
              >
                Logout{" "}
                <img
                  src="assets/img/logout.png"
                  className=""
                  alt=""
                  width="18px"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <EditRequest
        show={show}
        handleShow={handleShow}
        confirm={handleEditRequest}
      />
    </div>
  );
}

export default Navbar;
