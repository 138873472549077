import React, { useState, useEffect } from "react";
import ConsentModal from "./ConsentModal";
import MembershipModal from "./MembershipModal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addAddressSchema } from "../../utils/schema";
import parsePhoneNumber from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
// import DatePicker from "react-datepicker";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { addAddressCall } from "../slice";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";
import { monthOption, yearOptions } from "../../utils";
import Phone from "../../component/phoneInput/Phone";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

function Address() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState("");
  const [yearList, setYearList] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    control,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addAddressSchema),
    shouldFocusError: true,
  });
  const watchMonth = watch("month");
  const watchMobile = watch("mobile");

  const onSubmit = (data) => {
    const valid = data?.mobile ? isValid(data?.mobile) : true;
    if (valid) {
      setLoading(true);
      const countryCode = data?.mobile
        ? formatPhoneNumberIntl(data?.mobile).split(" ")[0]
        : "";
      dispatch(
        addAddressCall({
          property_number: data?.propertyNumber,
          property_name: data?.propertyName,
          street_number: data?.streetName,
          town: data?.town,
          country: data?.country,
          postal_code: data?.postalCode,
          mobile: data?.mobile ? data?.mobile.replace(countryCode, "") : "",
          countryCode: data?.mobile ? countryCode : "",
          dob: `${data?.year}-${data?.month}-${data?.day}`,
          dob_day: data?.day || "",
          dob_month: data?.month || "",
          dob_year: data?.year || "",
        })
      ).then((res) => {
        setLoading(false);
        if (res?.payload?.code === 1) {
          toast.success(res?.payload?.message);
          navigate(`/add-student-details`, {
            replace: true,
          });
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const isValid = (val) => {
    if (watchMobile && !isValidPhoneNumber(watchMobile)) {
      setError("mobile", { message: "Invalid Number" });
      return false;
    } else {
      setError("mobile", { message: "" });
    }
    return true;
  };

  useEffect(() => {
    if (yearList?.length === 0) {
      let minYear = moment().subtract(4, "years").format("YYYY");
      let years = [];
      for (let i = 1920; i <= minYear; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setYearList(years);
    }
  }, [yearList?.length]);

  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-style">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">address</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Address Property Number</label>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Address Property Number*"
                      {...register("propertyNumber")}
                    />
                    <img
                      src="assets/img/address.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.propertyNumber?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Address Property Name</label>

                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Address Property Name"
                      {...register("propertyName")}
                    />
                    <img
                      src="assets/img/address.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.propertyName?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Address Street Name</label>

                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Address Street Name*"
                      {...register("streetName")}
                    />
                    <img
                      src="assets/img/street.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.streetName?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="fw-bold">Town</label>

                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Town*"
                      {...register("town")}
                    />
                    <img
                      src="assets/img/town.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">{errors?.town?.message}</span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="fw-bold">Country</label>

                  <div className="input-container">
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <select
                          className="form-control ps-4 form-select"
                          name="country"
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          value={value}
                        >
                          <option value="">Country*</option>
                          <option value="UK">U.K</option>
                        </select>
                      )}
                      name="country"
                      control={control}
                      defaultValue={null}
                    />
                    <img
                      src="assets/img/address.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.country?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Postal Code</label>

                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Postal Code*"
                      {...register("postalCode")}
                    />
                    <img
                      src="assets/img/PostCode.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.postalCode?.message}
                    </span>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Mobile Number</label>
                  <div className="input-container">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Phone
                          onChange={onChange}
                          value={value}
                          className="form-control ps-4"
                          setValue={setValue}
                          name="mobile"
                          isValid={isValid}
                        />
                      )}
                      name="mobile"
                      control={control}
                      value={getValues("countryCode") + getValues("mobile")}
                      defaultValue={null}
                    />
                    <img
                      src="assets/img/mobile.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.mobile?.message}
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label className="fw-bold">
                    Account Holder's Date Of Birth
                  </label>
                </div>
                <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                  <div className="input-container">
                    <Controller
                      name="year"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <select
                          onChange={(e) => {
                            setValue("year", e.target.value);
                          }}
                          value={value}
                          className="custom-select form-control ps-4"
                        >
                          <option value="">Select Year</option>
                          {/* <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>
                          <option value="1999">1999</option>
                          <option value="1998">1998</option>
                          <option value="1997">1997</option>
                          <option value="1996">1996</option>
                          <option value="1995">1995</option>
                          <option value="1994">1994</option>
                          <option value="1993">1993</option>
                          <option value="1992">1992</option>
                          <option value="1991">1991</option>
                          <option value="1990">1990</option>
                          <option value="1989">1989</option>
                          <option value="1987">1987</option>
                          <option value="1986">1986</option>
                          <option value="1985">1985</option>
                          <option value="1984">1984</option>
                          <option value="1983">1983</option>
                          <option value="1982">1982</option>
                          <option value="1981">1981</option>
                          <option value="1980">1980</option> */}

                          {yearList?.map((item) => {
                            return (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />

                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">{errors?.year?.message}</span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                  <div className="input-container">
                    <Controller
                      name="month"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select {...field} className="form-control ps-4">
                          <option>Select Month</option>
                          {monthOption?.map((item) => (
                            <option value={item?.id} key={item?.id}>
                              {item?.value}
                            </option>
                          ))}
                        </select>
                      )}
                    />

                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.month?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                  <div className="input-container">
                    <Controller
                      name="day"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select {...field} className="form-control ps-4">
                          <option>Select Day</option>
                          {[...Array(watchMonth === "2" ? 28 : 31).keys()].map(
                            (item) => {
                              return (
                                <option value={item + 1}>{item + 1}</option>
                              );
                            }
                          )}
                        </select>
                      )}
                    />

                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">{errors?.day?.message}</span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-submit cursor-pointer"
                  >
                    {loading ? <Loader /> : "NEXT"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Address;
