import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { addEventToCart } from "../../api/Events";
import AddEventToCart from "../../component/Modal/AddEventToCart";
import Delete from "../../component/Modal/Delete";
import { useToggle } from "../../utils/hooks";
import {
  DeleteCartItem,
  FetchCartEventList,
  GetCartClassList,
  GetCartClassListSelector,
  GetCartList,
  GetCartListSelector,
  getEventAddedToCartListSelector,
  GetStudentList,
  schoolListSelector,
  studentListSelector,
  userDetailSelector,
} from "../slice";
import EventCardMain from "./../../component/Event_Card";
import Loader from "./../../component/Loader";
import ReadMore from "./../../component/ReadMore/ReadMore";
import {
  details,
  FetchEventDetail,
  FetchOtherEventList,
  getOtherEventLists,
  isAddedToCartSelector,
  setEventDetail,
} from "./EventSlice";

function EventDetail() {
  const dispatch = useDispatch();
  const { id, studentId } = useParams();
  const navigate = useNavigate();
  const userDetail = useSelector(userDetailSelector);

  const detail = useSelector((state) => state.event.detail);
  const otherList = useSelector(getOtherEventLists);
  const eventList = useSelector(getEventAddedToCartListSelector);
  const isAdded = useSelector(isAddedToCartSelector);
  const [show, toggle] = useToggle(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [addFor, setAddFor] = useState("");
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const studentList = useSelector(studentListSelector);

  const getStudentList = useCallback(() => {
    dispatch(GetStudentList({}));
  }, [dispatch]);

  useEffect(() => {
    getStudentList();
  }, [getStudentList]);

  const getDetails = useCallback(() => {
    dispatch(
      FetchEventDetail({
        id,
        studentId: { studentId: studentId ? studentId : "" },
      })
    );
  }, [dispatch, id, studentId]);

  useEffect(() => {
    getDetails();
    dispatch(FetchOtherEventList(id));
    dispatch(GetCartClassList({}));
    dispatch(FetchCartEventList({}));
  }, [getDetails, dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  const handleAddEventToCart = (studntdata, addFr, selectedLevel) => {
    console.log(studntdata, "Student data");
    console.log(detail, "event data");

    const payload = {
      schoolId: detail?.schoolId,
      eventId: detail?.id,
      area: detail?.areacode_details?.id,
      areacode: detail?.areacode_details?.id,
      date: detail?.date,
      price:
        detail?.levels?.length > 0
          ? ""
          : studntdata?.student_type?.toLowerCase() === "child"
          ? detail?.child_price
          : studntdata?.student_type?.toLowerCase() === "adult"
          ? detail?.adult_price
          : "",
      teacherId: detail?.teacher_details?.id,
      age_group: studntdata?.student_type,
      students: JSON.stringify([{ studentId: studntdata?.id?.toString() }]),
      levels:
        addFr === "event"
          ? JSON.stringify([])
          : JSON.stringify([
              {
                id: selectedLevel?.id,
                price:
                  studntdata?.student_type?.toLowerCase() === "child"
                    ? selectedLevel?.child_price
                    : studntdata?.student_type?.toLowerCase() === "adult"
                    ? selectedLevel?.adult_price
                    : "",
              },
            ]),
    };
    console.log(selectedLevel, "Selected Level");
    console.log(payload, "Payload");
    addEventToCart(payload).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        setSubmitting(false);
        getDetails();
        dispatch(FetchCartEventList({}));
        setShowStudentModal(false);
        setSelectedLevel([]);
        // if (!studentId) {
        //   navigate(`/event/${id}/${studntdata?.id}`);
        // }
      } else {
        toast.error(res?.message);
      }
    });
  };

  const handleAddLevelToCart = (data, addFr, level) => {
    handleAddEventToCart(data, addFr, level);
  };

  if (!detail) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else {
    return (
      <section className="sec-bg py-4 px-3">
        <div className="container-fluid">
          <div className="row">
            {userDetail?.consent?.length === 0 && (
              <div className="cols-m-12 text-center dangerMessage">
                {userDetail?.consent?.length === 0 ? (
                  <span className="">
                    You may not be able to make bookings if you have not entered
                    your consent preferences in ‘Edit Profile’ –{" "}
                    <span
                      onClick={() => {
                        navigate("/edit-profile");
                      }}
                      className="cursor-pointer"
                    >
                      <b>Click here</b>
                    </span>
                    .
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <div className="bg-white p-4 rounded h-100 shadow">
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
                    <div className="event-box-flex">
                      <span>
                        <img
                          src={
                            detail?.image?.includes("http")
                              ? detail?.image
                              : "assets/img/headerlogo.png"
                          }
                          className="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h4>{detail?.name || ""}</h4>
                        <p className="mb-0 ">
                          {detail?.age_group?.toLowerCase() === "both"
                            ? `Adult | Child`
                            : detail?.age_group}
                        </p>
                        <p className="mb-0 ">
                          {detail?.discipline_details?.name || ""}
                        </p>
                        <p className="mb-0">
                          <img
                            src="assets/img/date-of-birth.png"
                            className=""
                            alt=""
                          />{" "}
                          {detail?.date
                            ? moment(detail?.date).format("DD MMM YYYY")
                            : ""}
                        </p>
                        <p className="theme-color1 mb-0">
                          <img
                            src="assets/img/address.png"
                            className=""
                            alt=""
                          />{" "}
                          {detail?.school_details?.name +
                            ", " +
                            detail?.school_details?.address}
                        </p>

                        {detail?.areacode_details && (
                          <p className="mb-0">
                            <img
                              src="assets/img/address.png"
                              className=""
                              alt=""
                            />{" "}
                            {detail?.areacode_details?.areacode || ""}
                          </p>
                        )}

                        {detail?.teacher_details && (
                          <p className="mb-0">
                            <label>Teacher: </label>{" "}
                            {detail?.teacher_details?.full_name || ""}
                          </p>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4 text-center">
                    <div className="d-flex justify-content-center gap-2 align-items-baseline">
                      {!detail?.levels?.length > 0 &&
                      detail?.age_group.toLowerCase() === "adult" ? (
                        <span className="fw-bold theme-color mr-2 fs-4">
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {detail?.adult_price}
                        </span>
                      ) : !detail?.levels?.length > 0 &&
                        detail?.age_group.toLowerCase() === "child" ? (
                        <span className="fw-bold theme-color mr-2 fs-4">
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {detail?.child_price}
                        </span>
                      ) : !detail?.levels?.length > 0 &&
                        detail?.age_group.toLowerCase() === "both" ? (
                        <span className="fw-bold theme-color mr-2 fs-4">
                          Child:{" "}
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {detail?.child_price} Adult:{" "}
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {detail?.adult_price}
                        </span>
                      ) : null}
                      {!studentId &&
                      detail?.levels?.length === 0 &&
                      studentList?.length > 0 ? (
                        <button
                          className="btn btn-submit fs-5 mb-1 w-auto"
                          onClick={() => {
                            if (userDetail?.consent?.length === 0) {
                              toast.error(
                                "Kindly fill your consent for the profile to make bookings."
                              );
                            } else {
                              if (userDetail?.wallet < 0) {
                                navigate("/make-payment", {
                                  state: {
                                    amount: userDetail?.wallet,
                                    payment_for: "wallet",
                                  },
                                });
                                toast.error(
                                  "Kindly clear your pending wallet amount to continue booking."
                                );
                              } else {
                                if (detail?.isAddedInCart) {
                                  toast.error("Event already added to cart.");
                                } else {
                                  setAddFor("event");
                                  if (!studentId) {
                                    setShowStudentModal(true);
                                  } else {
                                    let arr = [...studentList];
                                    let studentData = arr.find(
                                      (it) => it?.id === parseInt(studentId)
                                    );
                                    handleAddEventToCart(
                                      studentData,
                                      "event",
                                      []
                                    );
                                  }
                                }
                              }
                            }
                          }}
                          disabled={
                            detail?.isAddedInCart ||
                            detail?.isBooked ||
                            userDetail?.consent?.length === 0
                          }
                        >
                          <img src="assets/img/cart.png" className="" alt="" />{" "}
                          {detail?.isAddedInCart
                            ? "Added to Cart"
                            : detail?.isBooked
                            ? "Already Booked"
                            : "Add to Cart"}
                          {/* Added to Cart */}
                        </button>
                      ) : studentId &&
                        detail?.levels?.length === 0 &&
                        studentList?.length > 0 &&
                        [...studentList]?.find(
                          (it) => it?.id === parseInt(studentId)
                        )?.trialAccountStatus === "3" ? (
                        <button
                          className="btn btn-submit fs-5 mb-1 w-auto"
                          onClick={() => {
                            if (userDetail?.wallet < 0) {
                              navigate("/make-payment", {
                                state: {
                                  amount: userDetail?.wallet,
                                  payment_for: "wallet",
                                },
                              });
                              toast.error(
                                "Kindly clear your pending wallet amount to continue booking."
                              );
                            } else {
                              if (detail?.isAddedInCart) {
                                toast.error("Event already added to cart.");
                              } else {
                                setAddFor("event");
                                if (!studentId) {
                                  setShowStudentModal(true);
                                } else {
                                  let arr = [...studentList];
                                  let studentData = arr.find(
                                    (it) => it?.id === parseInt(studentId)
                                  );
                                  handleAddEventToCart(
                                    studentData,
                                    "event",
                                    []
                                  );
                                }
                              }
                            }
                          }}
                          disabled={
                            detail?.isAddedInCart ||
                            detail?.isBooked ||
                            userDetail?.consent?.length === 0
                          }
                        >
                          <img src="assets/img/cart.png" className="" alt="" />{" "}
                          {detail?.isAddedInCart
                            ? "Added to Cart"
                            : detail?.isBooked
                            ? "Already Booked"
                            : "Add to Cart"}
                          {/* Added to Cart */}
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <span>
                      <ReadMore>{detail?.about}</ReadMore>
                    </span>
                  </div>

                  <div className="col-sm-12 mt-3">
                    {detail?.levels?.map((item, i) => {
                      return (
                        <>
                          <div className="mb-2" key={i}>
                            <div className="row align-items-center">
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <span className="fw-bold">
                                  Level: {item?.name}
                                </span>
                              </div>

                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <div className="d-sm-flex justify-content-end align-items-center gap-5">
                                  {detail?.age_group.toLowerCase() ===
                                  "adult" ? (
                                    <span className="text-start fw-bold theme-color mr-2 fs-4">
                                      Adult:{" "}
                                      <i
                                        className="fa fa-gbp"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {item?.adult_price}
                                    </span>
                                  ) : detail?.age_group.toLowerCase() ===
                                    "child" ? (
                                    <span className="text-start fw-bold theme-color mr-2 fs-4">
                                      Child:{" "}
                                      <i
                                        className="fa fa-gbp"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {item?.child_price}
                                    </span>
                                  ) : detail?.age_group.toLowerCase() ===
                                    "both" ? (
                                    <span className="text-start fw-bold theme-color mr-2 fs-4">
                                      Child:{" "}
                                      <i
                                        className="fa fa-gbp"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {item?.child_price} Adult:{" "}
                                      <i
                                        className="fa fa-gbp"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {item?.adult_price}
                                    </span>
                                  ) : null}
                                  {!studentId ? (
                                    <button
                                      className="btn btn-submit fs-5 mt-0 mb-0 w-auto py-1 fs-6"
                                      onClick={() => {
                                        if (userDetail?.wallet < 0) {
                                          navigate("/make-payment", {
                                            state: {
                                              amount: userDetail?.wallet,
                                              payment_for: "wallet",
                                            },
                                          });
                                          toast.error(
                                            "Kindly clear your pending wallet amount to continue booking."
                                          );
                                        } else {
                                          if (!item?.isBooked) {
                                            if (!item?.isAddedInCart) {
                                              setSelectedLevel(item);
                                              setAddFor("level");
                                              if (!studentId) {
                                                setShowStudentModal(true);
                                              } else {
                                                let arr = [...studentList];
                                                let studentData = arr?.find(
                                                  (it) =>
                                                    it?.id ===
                                                    parseInt(studentId)
                                                );

                                                handleAddLevelToCart(
                                                  studentData,
                                                  "level",
                                                  item
                                                );
                                              }
                                            } else {
                                              toast.error(
                                                "Level already added to cart."
                                              );
                                            }
                                          } else {
                                            toast.error(
                                              "Level already booked."
                                            );
                                          }
                                        }
                                      }}
                                      disabled={
                                        item?.isBooked ||
                                        item?.isAddedInCart ||
                                        userDetail?.consent?.length === 0
                                      }
                                    >
                                      <img
                                        src="assets/img/cart.png"
                                        className=""
                                        alt=""
                                      />{" "}
                                      {item?.isBooked
                                        ? "Already Booked"
                                        : item?.isAddedInCart
                                        ? "Added in cart"
                                        : "Add to cart"}
                                      {/* Added to Cart */}
                                    </button>
                                  ) : studentId &&
                                    studentList?.length > 0 &&
                                    [...studentList]?.find(
                                      (it) => it?.id === parseInt(studentId)
                                    )?.trialAccountStatus === "3" ? (
                                    <button
                                      className="btn btn-submit fs-5 mt-0 mb-0 w-auto py-1 fs-6"
                                      onClick={() => {
                                        if (userDetail?.wallet < 0) {
                                          navigate("/make-payment", {
                                            state: {
                                              amount: userDetail?.wallet,
                                              payment_for: "wallet",
                                            },
                                          });
                                          toast.error(
                                            "Kindly clear your pending wallet amount to continue booking."
                                          );
                                        } else {
                                          if (!item?.isBooked) {
                                            if (!item?.isAddedInCart) {
                                              setSelectedLevel(item);
                                              setAddFor("level");
                                              if (!studentId) {
                                                setShowStudentModal(true);
                                              } else {
                                                let arr = [...studentList];
                                                let studentData = arr?.find(
                                                  (it) =>
                                                    it?.id ===
                                                    parseInt(studentId)
                                                );

                                                handleAddLevelToCart(
                                                  studentData,
                                                  "level",
                                                  item
                                                );
                                              }
                                            } else {
                                              toast.error(
                                                "Level already added to cart."
                                              );
                                            }
                                          } else {
                                            toast.error(
                                              "Level already booked."
                                            );
                                          }
                                        }
                                      }}
                                      disabled={
                                        item?.isBooked ||
                                        item?.isAddedInCart ||
                                        userDetail?.consent?.length === 0
                                      }
                                    >
                                      <img
                                        src="assets/img/cart.png"
                                        className=""
                                        alt=""
                                      />{" "}
                                      {item?.isBooked
                                        ? "Already Booked"
                                        : item?.isAddedInCart
                                        ? "Added in cart"
                                        : "Add to cart"}
                                      {/* Added to Cart */}
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                              <p className="mt-2">
                                <ReadMore>{item?.note}</ReadMore>
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="bg-white p-4 rounded h-100 shadow">
              <h3>20 People are joining</h3>
            </div>
          </div> */}
            {otherList?.length > 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <hr />
              </div>
            )}

            {otherList?.length > 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <h3>Other Events</h3>
                <div className="row">
                  {otherList?.map((item, i) => {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3 mb-3 cursor-pointer"
                        key={i}
                        onClick={() => {
                          (async () => {
                            await localStorage.setItem(
                              "event",
                              JSON.stringify(item)
                            );
                          })();
                          dispatch(setEventDetail(item));
                          navigate(`/event/${item?.id}`);
                        }}
                      >
                        <EventCardMain
                          data={item}
                          key={i}
                          viewMore={() => {
                            (async () => {
                              await localStorage.setItem(
                                "event",
                                JSON.stringify(item)
                              );
                            })();
                            dispatch(setEventDetail(item));
                            navigate(`/event/${item?.id}`);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        {addFor && showStudentModal && selectedLevel && (
          <AddEventToCart
            show={showStudentModal}
            handleShow={(val) => {
              setAddFor("");
              setShowStudentModal(!showStudentModal);
              if (selectedLevel) {
                setSelectedLevel(null);
              }
            }}
            submitting={submitting}
            setSubmitting={setSubmitting}
            studentList={studentList}
            selectedLevel={selectedLevel}
            eventData={detail}
            done={(studentData) => {
              if (addFor === "event") {
                handleAddEventToCart(studentData, "event", []);
              } else if (addFor === "level") {
                handleAddLevelToCart(studentData, "level", selectedLevel);
              }
            }}
          />
        )}
      </section>
    );
  }
}

export default EventDetail;
