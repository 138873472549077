import React, { useCallback, useState } from "react";
import moment from "moment";
import { useEffect } from "react";

function StudentCard({
  item,
  student,
  setStudent,
  setDiscipline,
  setClass_type,
  setSchoolId,
  setAreaId,
  setAge_group,
  setSelectedTiming,
}) {
  const [isExpired, setIsExpired] = useState(false);
  const [isRenewal, setIsRenewal] = useState(true);

  const handleExpired = useCallback(() => {
    console.log(item, "item");
    const expiryDate = moment(item?.membership_details?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asHours();

    if (difference <= 0) {
      return true;
    }

    return false;
  }, [item]);
  const handleRenewalDate = useCallback(() => {
    const expiryDate = moment(item?.membership_details?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asDays();
    console.log(difference, "Data");
    let toHours = difference * 24 * 60 * 60;

    if (
      (Number(item?.membership_details?.membership_details?.no_of_days) > 30 &&
        difference < 30 &&
        difference > 0) ||
      (Number(item?.membership_details?.membership_details?.no_of_days) === 7 &&
        difference < 7 &&
        difference >= 1) ||
      (Number(item?.membership_details?.membership_details?.no_of_days) === 1 &&
        difference < 2 &&
        difference >= 1)
    ) {
      return true;
    }

    return false;
  }, [item]);

  useEffect(() => {
    if (item) {
      console.log(item, "Student card data");
      let ab = handleExpired();
      setIsExpired(ab);
      const isRenewald = handleRenewalDate();
      setIsRenewal(isRenewald);
    }
  }, [handleExpired, item]);

  const handleStudent = (e, item) => {
    let checked = e.target.checked;
    let arr = [...student];
    let index = arr.indexOf(item);

    if (checked) {
      if (index === -1) {
        arr = [item];
      }
    } else {
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setSelectedTiming([]);
    setAreaId(item?.areacode);
    setSchoolId(item?.schoolId);
    setDiscipline(item?.discipline);
    setClass_type(item?.student_type);
    setStudent(arr);
    setAge_group(arr?.[0]?.student_type);
  };
  return (
    <div className="mx-2">
      <input
        type="checkbox"
        name="students"
        id={`student-${item?.id}`}
        checked={
          student?.length > 0
            ? student?.findIndex((it) => it?.id === item?.id) !== -1
            : false
        }
        onChange={(e) => {
          handleStudent(e, item);
        }}
      />
      <label
        htmlFor={`student-${item?.id}`}
        className="student__details fullHeight"
      >
        <p>
          <img
            src={
              item?.image?.includes("http")
                ? item?.image
                : "assets/img/headerlogo.png"
            }
            className="student__img"
            alt=""
          />
        </p>
        <p className="d-grid mb-0">
          <span>student name</span>
          <span>
            <b>{item ? `${item?.first_name} ${item?.last_name}` : ""}</b>
          </span>
        </p>
        <p className="d-grid mb-0">
          <span>date of birth</span>
          <span>
            <b>
              {item?.dob
                ? moment(item?.dob).format("DD MMM YYYY")
                : item?.dob_year
                ? moment(
                    `${item?.dob_year}-${item?.dob_month}-${item?.dob_day}`
                  ).format("DD MMM YYYY")
                : ""}{" "}
              {item ? `(${item?.student_type})` : ""}
            </b>
          </span>
        </p>
        <p className="d-grid mb-0">
          <span>school venue</span>
          <span>
            <b>
              {item?.school_details?.name ? item?.school_details?.name : "NA"}
            </b>
          </span>
        </p>
        <p className="d-grid mb-0">
          <span>Status</span>
          <span>
            <b>
              {item?.trialAccountStatus === "1"
                ? "Trial"
                : item?.trialAccountStatus === "3" && isExpired
                ? "Inactive"
                : item?.trialAccountStatus === "3"
                ? "Active"
                : item?.trialAccountStatus === "2"
                ? "InActive"
                : item?.trialAccountStatus === "0" ||
                  item?.membership_details === null
                ? "No Membership"
                : "NA"}
            </b>
          </span>
        </p>
        <p className="d-grid mb-0">
          <span>Discipline</span>
          <span>
            <b>{item?.discipline_details?.name || "NA"}</b>
          </span>
        </p>
        <span>
          <small
            className={
              (item?.trialAccountStatus === "3" && isRenewal) ||
              (item?.trialAccountStatus === "3" && isExpired)
                ? "text-danger"
                : ""
            }
          >
            {item?.trialAccountStatus === "3" &&
            item?.membership_details !== null &&
            isExpired
              ? `Your membership is expired. Kindly renew.`
              : item?.trialAccountStatus === "3" &&
                item?.membership_details !== null &&
                isRenewal
              ? `Your membership expires soon. Kindly renew.`
              : ""}
          </small>
        </span>
      </label>
    </div>
  );
}

export default StudentCard;
