import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  consentCall,
  details,
  FetchUserDetail,
  userDetailSelector,
} from "../slice";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadFile } from "react-s3";
import { config, monthOption, phoneRegExp } from "../../utils";
import { Progress } from "react-sweet-progress";
import { editUserProfileSchema } from "../../utils/schema";
import DatePicker from "react-multi-date-picker";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { editUserProfile } from "../../api/student";
import { toast } from "react-toastify";
import { parsePhoneNumber } from "libphonenumber-js";
import Loader from "../../component/Loader";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import Phone from "../../component/phoneInput/Phone";
import { getData } from "../../api";

function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(userDetailSelector);
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [countryData, setCountryData] = useState("");
  const [loading, setLoading] = useState(false);
  const [onSubmitloading, setOnSubmitloading] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [consentListings, setConsentListings] = useState([]);
  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    watch,
    setError,
    setValue,
    control,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(editUserProfileSchema),
    shouldFocusError: true,
  });

  const watchDay = watch("day");
  const watchMonth = watch("month");
  const watchYear = watch("year");
  const watchImage = watch("image");
  const watchConsentListing = watch("consentListing");
  console.log(watchConsentListing, "Consent Listing");
  const watchuserConsents = watch("user_consents");

  useEffect(() => {
    dispatch(FetchUserDetail({}));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    dispatch(FetchUserDetail({})).then((res) => {
      if (res?.payload?.code === 1) {
        let data = res?.payload?.data?.user;

        getData("admin/consentList", { page: 1, limit: 100000 }).then((res) => {
          if (res?.code === 1) {
            console.log(res, "Consent list");

            reset({
              consentListing: res?.data?.consent_list,
              image: data?.image?.includes("http") ? data?.image : "",
              first_name: data?.first_name,
              last_name: data?.last_name,
              username: data?.username,
              email: data?.email,
              property_number: data?.address_details?.property_number,
              property_name: data?.address_details?.property_name,
              street_number: data?.address_details?.street_number,
              town: data?.address_details?.town,
              country: data?.address_details?.country,
              postal_code: data?.address_details?.postal_code,
              mobile:
                data?.address_details?.mobile &&
                data?.address_details?.countryCode?.charAt(0) === "+"
                  ? `${data?.address_details?.countryCode}${data?.address_details?.mobile}`
                  : data?.address_details?.mobile &&
                    data?.address_details?.countryCode?.charAt(0) !== "+"
                  ? `+${data?.address_details?.countryCode}${data?.address_details?.mobile}`
                  : "",
              // dob: new Date(data?.address_details?.dob),
              dob_day: data?.address_details?.dob_day
                ? data?.address_details?.dob_day
                : data?.address_details?.dob &&
                  data?.address_details?.dob !== "0000-00-00"
                ? new Date(data?.address_details?.dob).getDate()
                : "",
              dob_month: data?.address_details?.dob_month
                ? data?.address_details?.dob_month
                : data?.address_details?.dob &&
                  data?.address_details?.dob !== "0000-00-00"
                ? new Date(data?.address_details?.dob).getMonth() + 1
                : "",
              dob_year: data?.address_details?.dob_year
                ? data?.address_details?.dob_year
                : data?.address_details?.dob &&
                  data?.address_details?.dob !== "0000-00-00"
                ? new Date(data?.address_details?.dob).getFullYear()
                : "",
              user_consents: data?.consent || [],
            });
          }
        });
      }
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  const onSubmit = ({ user_consents, consentListing, ...data }) => {
    const phoneNumberisValid = data?.mobile ? isValid(data?.mobile) : true;
    console.log(watchuserConsents, "User consents marked");
    let consen = [...watchuserConsents];
    let isConsentMarked = consen?.every(
      (it) => it?.value !== null && it?.value !== "" && it?.value !== undefined
    );
    if (isConsentMarked) {
      if (phoneNumberisValid) {
        let countryCode = data?.mobile
          ? formatPhoneNumberIntl(data?.mobile).split(" ")[0]
          : "";

        let mobile = data?.mobile?.replace(countryCode, "");

        countryCode = "+44";
        console.log(data, "Other data ");
        const payload = {
          ...data,
          // mobile: data?.mobile ? data?.mobile?.replace(countryCode, "") : "",
          mobile,
          countryCode: data?.mobile ? countryCode : "",
        };
        setOnSubmitloading(true);
        console.log(payload);

        editUserProfile(payload).then((res) => {
          setOnSubmitloading(false);
          if (res?.code === 1) {
            addUserConsent(res?.data?.user?.id);
            // if (res?.data?.user?.canEditConsent) {
            // }
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
          return res?.data?.user?.id;
        });
      } else {
        setError("mobile", { message: "Kindly enter a valid phone number." });
      }
    } else {
      toast.error("Kindly mark all the consent points.");
    }
  };

  const addUserConsent = (id) => {
    const data = watchuserConsents;
    const payload = {
      userId: id,
      user_consents: data?.map((it) => {
        return {
          consent: it?.consent,
          value: it?.value,
          note: it?.note ? it?.note : "",
        };
      }),
    };
    console.log(data, payload, "User consent payload");

    return dispatch(consentCall(payload)).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        dispatch(FetchUserDetail({}));
      } else {
        toast.error(res?.payload?.message);
      }
      return res;
    });
  };

  const handleImage = (e) => {
    const { files } = e.target;
    setIsActive(true);

    uploadFile(files[0], config)
      .then((data) => {
        setValue("image", data?.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };
  const watchMobile = watch("mobile");

  const isValid = (val) => {
    if (watchMobile && !isValidPhoneNumber(watchMobile)) {
      setError("mobile", { message: "Invalid Number" });
      return false;
    } else {
      setError("mobile", { message: "" });
    }
    return true;
  };

  console.log(getValues());

  useEffect(() => {
    if (yearList?.length === 0) {
      let minYear = moment().subtract(4, "years").format("YYYY");
      let years = [];
      for (let i = 1920; i <= minYear; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setYearList(years);
    }
  }, [yearList?.length]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <section className="sec-bg py-4 px-3 bottom-space">
        <div className="container">
          <div className="form-style form-mw">
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                <h2 className="mdl-ttl">edit profile</h2>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 text-center">
                <div className="upload-btn-wrapper ">
                  <button className="btn">
                    {time > 0 && isActive ? (
                      <Progress type="circle" width={50} percent={time} />
                    ) : (
                      <img
                        src={
                          getValues("image")
                            ? getValues("image")
                            : "assets/img/Stdt1.png"
                        }
                        alt=""
                      />
                    )}

                    {time > 0 && isActive ? null : (
                      <div className="camera-icon">
                        <i className="fa fa-camera"></i>
                      </div>
                    )}
                  </button>

                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        handleImage(e);
                      }
                    }}
                  />
                </div>
              </div>
              {errors.image && (
                <span className="text-danger">{errors?.image?.message}</span>
              )}
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">First Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="First Name"
                    {...register("first_name")}
                  />
                  <img
                    src="assets/img/First-Name.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.first_name && (
                  <span className="text-danger">
                    {errors?.first_name?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Last Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="Last Name"
                    {...register("last_name")}
                  />
                  <img
                    src="assets/img/First-Name.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.last_name && (
                  <span className="text-danger">
                    {errors?.last_name?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Username</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="User Name"
                    {...register("username")}
                  />
                  <img
                    src="assets/img/First-Name.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.username && (
                  <span className="text-danger">
                    {errors?.username?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Email</label>
                <div className="input-container">
                  <input
                    type="email"
                    className="form-control ps-4"
                    placeholder="Email Address"
                    {...register("email")}
                  />
                  <img
                    src="assets/img/email.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.email && (
                  <span className="text-danger">{errors?.email?.message}</span>
                )}
              </div>

              <div className="col-sm-12">
                <label className="fw-bold">Date Of Birth</label>
              </div>

              <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                <div className="input-container">
                  <Controller
                    name="dob_year"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <select
                        onChange={(e) => {
                          setValue("dob_year", e.target.value);
                        }}
                        value={value}
                        className="form-control ps-4"
                      >
                        <option value="">Select Year</option>
                        {/* {years?.map((item, i) => (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          ))} */}
                        {/* <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>
                        <option value="1999">1999</option>
                        <option value="1998">1998</option>
                        <option value="1997">1997</option>
                        <option value="1996">1996</option>
                        <option value="1995">1995</option>
                        <option value="1994">1994</option>
                        <option value="1993">1993</option>
                        <option value="1992">1992</option>
                        <option value="1991">1991</option>
                        <option value="1990">1990</option>
                        <option value="1989">1989</option>
                        <option value="1987">1987</option>
                        <option value="1986">1986</option>
                        <option value="1985">1985</option>
                        <option value="1984">1984</option>
                        <option value="1983">1983</option>
                        <option value="1982">1982</option>
                        <option value="1981">1981</option>
                        <option value="1980">1980</option> */}

                        {yearList?.map((item) => {
                          return (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  />

                  <img
                    src="assets/img/date-of-birth.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">
                    {errors?.dob_year?.message}
                  </span>
                )}
              </div>

              <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                <div className="input-container">
                  <Controller
                    name="dob_month"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <select
                        onChange={(e) => {
                          setValue("dob_month", e.target.value);
                        }}
                        value={value}
                        className="form-control ps-4"
                      >
                        <option value="">Select Month</option>
                        {monthOption?.map((item) => (
                          <option value={item?.id} key={item?.id}>
                            {item?.value}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  <img
                    src="assets/img/date-of-birth.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">
                    {errors?.dob_month?.message}
                  </span>
                )}
              </div>

              <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                <div className="input-container">
                  <Controller
                    name="dob_day"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <select
                        onChange={(e) => {
                          setValue("dob_day", e.target.value);
                        }}
                        value={value}
                        className="form-control ps-4"
                      >
                        <option value={""}>Select Day</option>
                        {[...Array(watchMonth === "2" ? 28 : 31).keys()].map(
                          (item, i) => {
                            return (
                              <option value={item + 1} key={i}>
                                {item + 1}
                              </option>
                            );
                          }
                        )}
                      </select>
                    )}
                  />

                  <img
                    src="assets/img/date-of-birth.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">
                    {errors?.dob_day?.message}
                  </span>
                )}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6"></div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                <label className="fw-bold">
                  <h4>Edit Address </h4>
                </label>
              </div>

              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Property Number</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="Address Property Number*"
                    {...register("property_number")}
                  />
                  <img
                    src="assets/img/address.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">
                    {errors?.property_number?.message}
                  </span>
                )}
              </div>

              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Property Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="Address Property Name"
                    {...register("property_name")}
                  />
                  <img
                    src="assets/img/address.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">
                    {errors?.property_name?.message}
                  </span>
                )}
              </div>

              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Street Name*</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="Address Street Name*"
                    {...register("street_number")}
                  />
                  <img
                    src="assets/img/street.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">
                    {errors?.street_number?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Town</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="Town*"
                    {...register("town")}
                  />
                  <img src="assets/img/town.png" className="input-img" alt="" />
                </div>
                {errors && (
                  <span className="text-danger">{errors?.town?.message}</span>
                )}
              </div>

              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Country</label>
                <div className="input-container">
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <select
                        className="form-control ps-4 form-select"
                        name="country"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        value={value}
                      >
                        <option value="">Country*</option>
                        <option value="UK">U.K</option>
                      </select>
                    )}
                    name="country"
                    control={control}
                    defaultValue={null}
                  />
                  <img
                    src="assets/img/address.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">
                    {errors?.country?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Postal Code</label>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-4"
                    placeholder="Post Code*"
                    {...register("postal_code")}
                  />
                  <img
                    src="assets/img/PostCode.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors?.postal_code && (
                  <span className="text-danger">
                    {errors?.postal_code?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <label className="fw-bold">Mobile Number</label>
                <div className="input-container">
                  {/* <input
                  type="text"
                  className="form-control ps-4"
                  placeholder="Mobile Number"
                  value="980365418"
                /> */}
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      // <PhoneInput
                      //   inputProps={{
                      //     name: "mobile",
                      //   }}
                      //   country="gb"
                      //   enableSearch
                      //   countryCodeEditable={false}
                      //   value={
                      //     getValues("mobile")
                      //       ? getValues("countryCode") + getValues("mobile")
                      //       : undefined
                      //   }
                      //   containerClass={"form-control pl-5"}
                      //   inputClass={"phoneInputClass"}
                      //   onChange={(phone, country, e, formattedValue) => {
                      //     const raw = phone.slice(country?.dialCode?.length);
                      //     setCountryData({ formattedValue, country });

                      //     setValue("mobile", raw);
                      //     setValue("countryCode", formattedValue?.split(" ")[0]);
                      //   }}
                      // />
                      <Phone
                        onChange={onChange}
                        value={value}
                        className="form-control ps-4"
                        setValue={setValue}
                        name="mobile"
                        isValid={isValid}
                      />
                    )}
                    name="mobile"
                    control={control}
                    value={getValues("countryCode") + getValues("mobile")}
                    defaultValue={null}
                  />
                  <img
                    src="assets/img/mobile.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors && (
                  <span className="text-danger">{errors?.mobile?.message}</span>
                )}
              </div>

              <div className="form-group col-sm-12 col-md-12 mb-4 bg-white p-3">
                <label className="fw-bold">Consents</label>
                {errors?.user_consents && (
                  <span className="text-danger">
                    {errors?.user_consents?.message}
                  </span>
                )}

                {watchConsentListing?.length > 0
                  ? watchConsentListing?.map((cons, index) => {
                      return (
                        <div className=" mt-3">
                          <label>{cons?.label}</label>

                          <div className="termCustomRadio">
                            <p>
                              {cons?.values?.map((val, ind) => {
                                return (
                                  <span className="me-3">
                                    <Controller
                                      name={`user_consents.${index}.value`}
                                      control={control}
                                      defaultValue={null}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <input
                                          type={cons?.input_field_type}
                                          // disabled={!data?.canEditConsent}
                                          id={`test${index}${ind}`}
                                          name={`user_consents.${index}.value`}
                                          checked={
                                            val?.value?.toLowerCase() ===
                                            watchuserConsents?.[
                                              index
                                            ]?.value?.toLowerCase()
                                          }
                                          onChange={() => {
                                            let ob = {
                                              consent: cons?.id,
                                              value: val?.value,
                                              note: "",
                                            };
                                            setValue(
                                              `user_consents.${index}`,
                                              ob
                                            );
                                          }}
                                        />
                                      )}
                                    />
                                    <label htmlFor={`test${index}${ind}`}>
                                      {val?.value}
                                    </label>
                                  </span>
                                );
                              })}
                            </p>
                            {cons?.id === 6 &&
                              watchuserConsents?.[
                                index
                              ]?.value?.toLowerCase() === "yes" && (
                                <span>Medication: {cons?.note}</span>
                              )}

                            {cons?.id === 6 &&
                              watchuserConsents?.[
                                index
                              ]?.value?.toLowerCase() === "yes" && (
                                <>
                                  {" "}
                                  <div class="input-container term-checkbox mt-4 ms-3 d-lg-flex align-items-center">
                                    <span class="position-relative align-items-center">
                                      <Controller
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <input
                                            type="text"
                                            class="textBox form-control ms-2 medication"
                                            placeholder="Name of medication and why it is required?"
                                            name={`user_consents.${index}.note`}
                                            value={value}
                                            onChange={(e) => {
                                              setValue(
                                                `user_consents.${index}.note`,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        )}
                                        name={`user_consents.${index}.note`}
                                      />
                                    </span>
                                  </div>
                                  {watchuserConsents?.[index]?.note?.length <
                                    1 && (
                                    <span className="text-danger">
                                      Required
                                    </span>
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      );
                    })
                  : null}
                {/* {watchuserConsents?.length > 0
                  ? watchuserConsents?.map((cons, index) => {
                      return (
                        <div className=" mt-3">
                          <label>{cons?.consent_details?.label}</label>

                          <div className="termCustomRadio">
                            <p>
                              {cons?.consent_details?.values?.map(
                                (val, ind) => {
                                  return (
                                    <span className="me-3">
                                      <Controller
                                        name={`user_consents.${index}.value`}
                                        control={control}
                                        defaultValue={null}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <input
                                            type={
                                              cons?.consent_details
                                                ?.input_field_type
                                            }
                                            disabled={!data?.canEditConsent}
                                            id={`test${index}${ind}`}
                                            name={`user_consents.${index}.value`}
                                            checked={
                                              val?.value?.toLowerCase() ===
                                              cons?.value?.toLowerCase()
                                            }
                                            onChange={() => {
                                              onChange(val?.value);
                                              console.log(
                                                watchuserConsents,
                                                "After"
                                              );
                                            }}
                                          />
                                        )}
                                      />
                                      <label htmlFor={`test${index}${ind}`}>
                                        {val?.value}
                                      </label>
                                    </span>
                                  );
                                }
                              )}
                            </p>
                            {cons?.consent === 6 &&
                              watchuserConsents?.[
                                index
                              ]?.value?.toLowerCase() === "yes" &&
                              !data?.canEditConsent && (
                                <span>Medication: {cons?.note}</span>
                              )}

                            {cons?.consent === 6 &&
                              watchuserConsents?.[
                                index
                              ]?.value?.toLowerCase() === "yes" &&
                              data?.canEditConsent && (
                                <>
                                  {" "}
                                  <div class="input-container term-checkbox mt-4 ms-3 d-lg-flex align-items-center">
                                    <span class="position-relative align-items-center">
                                      <Controller
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <input
                                            type="text"
                                            class="textBox form-control ms-2 medication"
                                            placeholder="Name of medication and why it is required?"
                                            name={`user_consents.${index}.note`}
                                            value={value}
                                            onChange={(e) => {
                                              setValue(
                                                `user_consents.${index}.note`,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        )}
                                        name={`user_consents.${index}.note`}
                                      />
                                    </span>
                                  </div>
                                  {watchuserConsents?.[index]?.note?.length <
                                    1 && (
                                    <span className="text-danger">
                                      Required
                                    </span>
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      );
                    })
                  :} */}
                {/* {errors?.consent1 && (
                  <span className="text-danger">
                    {errors?.consent1?.message}
                  </span>
                )} */}
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <button type="submit" className="btn btn-submit">
                  {onSubmitloading ? <Loader /> : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default EditProfile;
